<template>
  <div>
    <SocialSidebar />
    <Nav :logo="logo" />
    <section id="home">  
          <div id="presentation">
            <div class="text_presentation">
              <div class="text_block">
                <p>
                  {{ description }}
                </p>
                <button class="cta_button" @click="redirectToLink">{{ ctaText }}</button>
              </div>
              <div class="image_block">
                <div
                  v-for="(circle, index) in circles"
                  :key="index"
                  class="circle"
                  :style="{
                    width: circle.size + 'px',
                    height: circle.size + 'px',
                    borderColor: circle.color,
                    top: circle.top ? circle.top + 'px' : 'auto',
                    left: circle.left ? circle.left + 'px' : 'auto',
                    bottom: circle.bottom ? circle.bottom + 'px' : 'auto',
                    right: circle.right ? circle.right + 'px' : 'auto',
                    animationDelay: circle.animationDelay,
                  }"
                ></div>
                <img :src="logoSrc" :alt="logoAlt">
              </div>
            </div>
          </div>
  </section>

    
    <section id="services">

            <div class="header-container">
                <h1 class="portfolio-title">NOS EXPERTISES</h1>
                <p class="subtitle">Nos Services</p>
                <hr class="divider" />
            </div>

            <div class="presta-container">
                <div
                    v-for="(card, index) in cardss"
                    :key="index"
                    :class="['presta-card', { flipped: flippedCards[index] }, card.customClass]"
                    @click="flipCard(index)"
                >
                    <!-- Face avant -->
                    <div class="front">
                        <div class="icon">
                            <i :class="card.iconClass"></i>
                        </div>
                        <h3>{{ card.title }}</h3>
                        <ul class="desctext">
                            <li v-for="(item, idx) in card.items" :key="idx" v-html="item"></li>
                        </ul>
                    </div>

                    <!-- Face arrière -->
                    <div class="back">
                        <h3> Quels sont les avantages ?</h3>
                        <ul class="advantages-list">
                            <li v-for="(advantage, idx) in card.advantages" :key="idx">
                                {{ advantage }}
                            </li>
                        </ul>
                    </div>
                </div>
              </div>

    </section>


    <!-- card -->

    <section id="site">
       <div class="header-container">
          <h1 class="portfolio-title">NOS RÉALISATIONS</h1>
          <p class="subtitle">Nos créations</p>
          <hr class="divider" />
       </div>

       <div class="cards-container">
          <Card
            v-for="(card, index) in cards"
            :key="index"
            :logo="card.logo"
            :title="card.title"
            :tags="card.tags"
            :description="card.description"
            :background="card.background"
            :link="card.link"
            
          />
       </div>
    </section>


    <section id="contact">
      <div>
        <div class="header-container">
            <h1 class="portfolio-title">CONTACT</h1>
            <p class="subtitle">Prendre contact</p>
            <hr class="divider" />
        </div>
        <ContactSection />
      </div>

    </section>




    <Footer />

  </div>
</template>

<script>
import Nav from "../components/Nav.vue";
import SocialSidebar from "../components/SocialSidebar.vue";
import Footer from "../components/Footer.vue";
import ContactSection from "../components/ContactSection.vue";
import Card from "../components/Card.vue";

// images
import logoinnolive from '@/assets/images/logo-innolive.png';
import logo_epicerie from '@/assets/images/logo_mon_epicerie.png';
import logoblondeau from '@/assets/images/blondeau.png';
import mylogo from '@/assets/images/logo-transparent.png';
// images pc
import pcinnolive from '@/assets/images/logo_pc_innolive.png';
import pcepicerie from '@/assets/images/pc_epicerie.png';
import pcblondeau from '@/assets/images/logopc_blondeau.png';





export default {
  components:{
    Nav,
    Footer,
    Card,
    SocialSidebar,
    ContactSection,
  },
  methods:{
    redirectToLink(link){
      window.location.href =  link;
      window.location.href = this.linkUrl;
    },
    flipCard(index) {
      this.$set(this.flippedCards, index, !this.flippedCards[index]);
    },

   
  },
  mounted() {
    this.flippedCards = this.cardss.map(() => false);
  },
  data() {
    return {
      // name: "Concept Génération",
      logo: mylogo,
      logoSrc: "https://image.noelshack.com/fichiers/2024/48/4/1732798359-valentin.jpg",
    logoAlt: "Illustration représentant le développement web",
    description: "Valentin Gaultier, développeur web basé à Châteauroux, spécialisé en applications web et mobiles. Également formateur, j'interviens pour vous accompagner dans vos projets numériques. Que vous ayez besoin d'un site vitrine attractif ou d'une boutique en ligne efficace, je mets mon expertise à profit pour concrétiser vos ambitions digitales." ,
    ctaText: "Prendre Rendez-vous ",
    linkUrl: 'https://calendar.google.com/calendar/u/0/appointments/schedules/AcZssZ0LtkF-ZHf-gtQxlbGX2oAZV1xvJFS6VlG6FklypbyyFGfmS2ntH2X5bJ7tYOCWpFvtg1fOnSSR',
    circles: [
    {
        size: 150,
        color: "rgba(255, 123, 0, 0.5)",
        top: -40,
        left: -50,
        animationDelay: "0s",
      },
      {
        size: 100,
        color: "rgba(0, 150, 255, 0.5)",
        bottom: -30,
        right: -40,
        animationDelay: "2s",
      },
    ],
   

      flippedCards: [],
      cardss: [
        {
          title: "Création de site internet",
          iconClass: "icon-web",
          items: ["Conception de site vitrine ou blog pour développer son activité."],
          customClass: "web-card", 
          advantages: [
                    "Formation incluse (1h) pour gérer votre site",
                    "Maintenance offerte pendant 1 mois",
                    "Design personnalisé et unique",
                    "Navigation rapide et adaptable",
                    "Optimisation SEO"
                ]

        },


        {
          title: "Création de site E-ecommerce ",
          iconClass: "icon-e-ecommerce",
          items: [" Ensemble, construisons votre projet de création de site e-commerce."],
          customClass: "progiciel-card",
          advantages: [
                    "Formation incluse (1h) pour gérer votre boutique",
                    "Passerelle de paiement sécurisée",
                    "Conception ergonomique pour faciliter l’achat",
                    "Référencement optimisé pour tes produits",
                    "Suivi personnalisé post-livraison "
                ]

        },

        {
          title: "Refonte de site web ",
          iconClass: "icon-software",
          items: ["Un site qui capte l’attention, offre une meilleure expérience utilisateur et reflète parfaitement votre image de marque."],
          customClass: "card_trois",
          advantages: [
                    "Amélioration de l’ergonomie (UX/UI)",
                    "Audit gratuit avant refonte ",
                    "Sauvegarde complète avant travaux",
                    "Formation à la nouvelle version"
                ]

        },
        {
          title: "Maintenance de site web",
          iconClass: "icon-maintenance",
          items: ["Confiez-nous la gestion et l’entretien de votre site internet pour assurer son bon fonctionnement et sa pérennité. Nous veillons à ce qu’il reste à jour, sécurisé et parfaitement opérationnel."],
          customClass: "mobile-card",
          advantages: [
                    "Protection proactive de votre site",
                    "Mises à jour des plugins et du système",
                    "Sauvegardes automatiques incluses ",
                    "Résolution rapide des bugs",
                    "Monitoring continu"
                ]

        },

      ],


      cards: [
    {
        logo: logoinnolive,
        title: "Innolive",
        tags: ["Site vitrine"],
        description: "Innolive, votre atout marketing en production audiovisuelle.",
        background: pcinnolive, 
        link: "https://www.innolive.fr",
    },
    {
        logo: logo_epicerie,
        title: "Mon Épicerie Berrichonne",
        tags: ["E-commerce"],
        description: "Maison Chauveau et des produits de producteurs et d’artisans du Berry",
        background: pcepicerie,
        link: "https://mon-epicerie-berrichonne.fr/",
    },
    {
        logo: logoblondeau,
        title: "Blondeau Bâtiment",
        tags: ["Site vitrine"],
        description: "Découvrez les prestations de votre carreleur en neuf ou en rénovation.",
        background: pcblondeau,
        link: "https://blondeaubatiment.fr/",
    }
]


    };
  },
};
</script>

<style>
@import url(../assets/css/index.css);
@import url(../assets/css/card.css);
@import url(../assets/css/presta.css);

body{
  background-color: #fafafb;
  margin: 0;
  padding: auto;

}





</style>
